<template>
  <div class="body-2" data-test="klarna-modal">
    <RawKlarnaPlacement
      :base-price="props.basePrice"
      :data-key="settings.placementKey"
      :payment-method="klarnaPaymentMethod"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { usePaymentMethodsWithFallback } from '../../../methods'
import { useKlarnaLibrary } from '../../composables/useKlarnaLibrary'
import type { PaymentAdvertisingModalComponentOptions } from '../../types/PaymentAdvertisingConfig'
import { type PaymentAdvertisingModalProps } from '../../types/PaymentAdvertisingModalProps'
import RawKlarnaPlacement from '../KlarnaBanner/RawKlarnaPlacement.vue'

import {
  KLARNA_MODAL_SETTINGS,
  KLARNA_PAYMENT_METHODS,
} from './KlarnaModal.config'

const props = withDefaults(defineProps<PaymentAdvertisingModalProps>(), {
  name: 'KlarnaAdvertisement',
  paymentMethods: null,
})

defineOptions({
  hideIcon: true,
} satisfies PaymentAdvertisingModalComponentOptions)

const settings = computed(() => KLARNA_MODAL_SETTINGS[props.spot])
const paymentMethods = usePaymentMethodsWithFallback(() => props.paymentMethods)

const klarnaPaymentMethod = computed(() => {
  const paymentMethod = paymentMethods.value.find(({ bmCode }) =>
    KLARNA_PAYMENT_METHODS.includes(bmCode),
  )

  if (!paymentMethod) {
    throw new Error('Could not find any Klarna payment method')
  }

  return paymentMethod
})

useKlarnaLibrary(klarnaPaymentMethod.value)
</script>
