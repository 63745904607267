<template>
  <RevDialog :name="props.name" :title="i18n(translations.title)">
    <template #body>
      <div class="mb-24">
        <div class="flex flex-col items-center">
          <RevIllustration
            v-if="icon"
            alt=""
            :height="50"
            :src="icon"
            :width="90"
          />
        </div>

        <!-- The grade descriptions are sent by the API as HTML. -->
        <!-- TODO [PAYIN-3595] Remove HTML from Lokalize key -->
        <!-- // nosemgrep -->
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="my-20" v-html="i18n(translations.description)" />

        <RevButton
          anchor
          full-width="always"
          rel="nofollow"
          target="_blank"
          :to="{
            type: 'external',
            href: KLARNA_LOGIN_URL,
          }"
          variant="primary"
        >
          {{ i18n(translations.button) }}
        </RevButton>
      </div>
    </template>

    <template #trigger="slotProps">
      <slot v-bind="slotProps" name="trigger" />
    </template>
  </RevDialog>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { RevIllustration } from '@ds/components/Illustration'

import { paymentGroupIcon } from '../../../methods'
import { type PaymentAdvertisingModalProps } from '../../types/PaymentAdvertisingModalProps'
import { KLARNA_PAYMENT_METHODS } from '../KlarnaModal/KlarnaModal.config'

import translations from './KlarnaPaymentSummaryModal.translations'

const KLARNA_LOGIN_URL = 'https://app.klarna.com/login'

const i18n = useI18n()

const props = withDefaults(defineProps<PaymentAdvertisingModalProps>(), {
  name: 'KlarnaPaymentSummaryModal',
  paymentMethods: null,
})

const klarnaPaymentMethod = computed(() => {
  return (
    props.paymentMethods?.find(({ bmCode }) =>
      KLARNA_PAYMENT_METHODS.includes(bmCode),
    ) || null
  )
})

const icon = computed(() =>
  klarnaPaymentMethod.value
    ? paymentGroupIcon(klarnaPaymentMethod.value.group)
    : null,
)
</script>
