import { useAsyncData } from '#imports'
import { type MaybeRefOrGetter, toValue } from 'vue'

import { type Price } from '@backmarket/http-api'
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { getAsyncDataKey } from '@backmarket/nuxt-module-http-v2/getAsyncDataKey'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import type {
  GetPaymentMethodsOptions,
  PaymentTenant,
} from '../../../api/getPaymentMethods/getPaymentMethods.specs'
import { usePaymentMethods } from '../../../api/getPaymentMethods/usePaymentMethods.api'
import { filterABTestedPaymentMethods } from '../helpers/filterABTestedPaymentMethods'

/**
 * Returns payment methods for the current country that correspond to the given listings, bag price ...
 * Prefer the use of the cached version `useMarketPaymentMethods` when possible
 *
 * @example
 * const { data: paymentMethods } = await useMarketPaymentMethodsFor({
 *  listings: ['123', '456'],
 *  bagPrice: { amount: '99.99', currency: 'EUR' },
 * })
 */
export const useMarketPaymentMethodsFor = ({
  listings,
  bagPrice,
  hasInsurance,
  tokenization,
  tenant,
}: {
  listings?: MaybeRefOrGetter<string[]>
  bagPrice?: MaybeRefOrGetter<Price | undefined>
  hasInsurance?: MaybeRefOrGetter<boolean>
  tenant?: PaymentTenant

  /**
   * When true, only the payment methods that support tokenization will be returned
   */
  tokenization?: MaybeRefOrGetter<boolean>
}) => {
  const { market } = useMarketplace()

  const experiments = useExperiments()

  const getPaymentMethods = usePaymentMethods()
  const reactiveOptions = (): GetPaymentMethodsOptions => ({
    tenant: tenant ?? 'SALES',
    countryCode: market.countryCode,
    listings: toValue(listings),
    bagPrice: toValue(bagPrice),
    insuranceSubscription: toValue(hasInsurance),
    features: toValue(tokenization) ? ['tokenizable'] : undefined,
  })

  return useAsyncData(
    // Keys are not reactive. It's important that the options passed are correct from the beginning, especially on the server side for request deduplication.
    // For example, if you pass an empty object as options and then update it, the key will not change.
    // Two different calls that started with the same empty object will have the same key, even if the options are different later on.
    // This situation is unlikely but possible.
    // Also, because of this, it's difficult to cache the data for future requests as `getCachedData` will always receive the same key, even if the options start to be different.
    // It may be fixed in the future: https://github.com/nuxt/nuxt/issues/21532
    getAsyncDataKey('use-market-payment-methods-for', reactiveOptions()),
    () => getPaymentMethods(reactiveOptions()),
    {
      transform: (data) =>
        filterABTestedPaymentMethods(experiments, data.results),
      dedupe: 'defer',
      watch: [reactiveOptions],
    },
  )
}
