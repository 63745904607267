<template>
  <RevButtonBase
    :aria-label="COMPANY_NAME"
    :class="{ 'cursor-default': props.disabled }"
    :to="redirectionRoute"
    :tracking="{ name: 'logo', zone: 'header' }"
  >
    <RevIllustration
      :alt="COMPANY_NAME"
      :height="24"
      src="/img/header/Logo.svg"
      :width="180"
    />
  </RevButtonBase>
</template>

<script setup lang="ts">
import { useRouteLocationWithLocale, useRuntimeConfig } from '#imports'
import { computed } from 'vue'

import { RevButtonBase } from '@ds/components/ButtonBase'
import { RevIllustration } from '@ds/components/Illustration'

import { HOME } from '~/scopes/home/route-names'

import { useLoaderStore } from '../stores/loaderStore'

const props = withDefaults(defineProps<{ disabled?: boolean }>(), {
  disabled: false,
})

const loaderStore = useLoaderStore()

const runtimeConfig = useRuntimeConfig()
const { COMPANY_NAME } = runtimeConfig.public

const resolveLocalizedRoute = useRouteLocationWithLocale()

const redirectionRoute = computed(() => {
  if (!props.disabled) {
    loaderStore.enable()

    return resolveLocalizedRoute({ name: HOME })
  }

  return undefined
})
</script>
