<template>
  <RevSkeleton
    v-if="props.loading"
    :aria-label="i18n(translations.loading)"
    height="1lh"
    shape="rectangle"
    :width="props.skeletonWidth"
  />
  <p v-else>
    <slot name="default" />
  </p>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevSkeleton } from '@ds/components/Skeleton'

import translations from './LoadableText.translations'

const i18n = useI18n()

type LoadableTextProps = {
  loading?: boolean
  skeletonWidth?: string
}

const props = withDefaults(defineProps<LoadableTextProps>(), {
  loading: false,
  skeletonWidth: '100%',
})
</script>
