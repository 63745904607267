import type { PaymentAdvertisingConfigFilter } from '../../types/PaymentAdvertisingConfig'

export const isPaypalModalAvailable: PaymentAdvertisingConfigFilter = (
  methods,
) => {
  const compatiblePaypalMethod = methods.find(
    ({ bmCode, architecture, config }) =>
      bmCode === 'paypal' &&
      architecture === 'finpay' &&
      'paypalClientId' in config &&
      config?.paypalClientId,
  )

  if (!compatiblePaypalMethod) {
    return {
      isAvailable: false,
      forMethods: [],
    }
  }

  return {
    isAvailable: true,
    forMethods: [compatiblePaypalMethod],
  }
}
