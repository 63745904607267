<template>
  <div data-test="affirm-modal">
    <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
    <a
      ref="link"
      :class="settings.class"
      data-affirm-color="black"
      :data-amount="amount"
      data-learnmore-show="false"
      :data-page-type="settings.pageType"
      data-test="affirm-link"
    />
    <slot name="trigger" v-bind="{ open }" />
  </div>
</template>

<script setup lang="ts">
import { type Ref, computed, ref } from 'vue'

import { clamp } from '@backmarket/utils/math/clamp'
import { priceToMinorUnits } from '@backmarket/utils/math/priceToMinorUnits'

import type { PaymentAdvertisingModalProps } from '../../types/PaymentAdvertisingModalProps'

import {
  AFFIRM_MAXIMAL_AMOUNT,
  AFFIRM_MINIMAL_AMOUNT,
  AFFIRM_MODAL_SETTINGS,
} from './AffirmModal.config'
import { useAffirmLibrary } from './useAffirmLibrary'

const props = withDefaults(defineProps<PaymentAdvertisingModalProps>(), {
  name: 'AffirmModal',
  paymentMethods: null,
})

const opened = ref(false)
const link: Ref<HTMLAnchorElement | null> = ref(null)

const settings = computed(() => AFFIRM_MODAL_SETTINGS[props.spot] || {})

const amount = computed(() =>
  settings.value.requiresAmount
    ? clamp(
        priceToMinorUnits(props.basePrice),
        AFFIRM_MINIMAL_AMOUNT,
        AFFIRM_MAXIMAL_AMOUNT,
      )
    : undefined,
)

const { load } = useAffirmLibrary(
  () => {
    window.affirm?.events.on('prequal:close', () => {
      opened.value = false
    })
    window.affirm?.ui.ready(async () => {
      window.affirm?.ui.refresh()
    })
  },
  // Lazy-load the library, to improve performance (especially important on the product page).
  { immediate: false },
)

async function open() {
  if (!opened.value) {
    opened.value = true

    await load()

    window?.affirm?.ui.refresh()
    link.value?.click()
  }
}
</script>
