import {
  type UseScriptTagOptions,
  useRuntimeConfig,
  useScriptTag,
} from '#imports'

import {
  AFFIRM_SDK_URL_PRODUCTION,
  AFFIRM_SDK_URL_SANDBOX,
} from './AffirmModal.config'

export function useAffirmLibrary(
  onLibraryLoaded: () => void,
  options: UseScriptTagOptions = {},
) {
  const { AFFIRM_API_KEY, AFFIRM_SANDBOX_ENABLED } = useRuntimeConfig().public

  if (process.client) {
    // eslint-disable-next-line no-underscore-dangle
    window._affirm_config = {
      public_api_key: AFFIRM_API_KEY,
    }
  }

  // Using the production version of the SDK with a sandbox API key results in
  // a HTTP 400 response on checkout request, so we have to use a specific SDK
  // for each environment.
  //
  // Also, we need to load SDK on-the-fly to avoid the risk of using an
  // out-of-date version.
  const url = AFFIRM_SANDBOX_ENABLED
    ? AFFIRM_SDK_URL_SANDBOX
    : AFFIRM_SDK_URL_PRODUCTION

  return useScriptTag(url, onLibraryLoaded, options)
}
