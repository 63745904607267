import type { RouteRecordName } from 'vue-router'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

export default () => {
  return ({
    component: { name, props },
    err,
    info,
    routeName,
  }: {
    component: { name: string; props: unknown }
    err: Error
    info: string
    routeName: RouteRecordName | null | undefined
  }) => {
    const logger = useLogger()
    const componentTag = name

    const errorLog = {
      componentName: componentTag,
      details: {
        message: err.message,
        stack: err.stack,
      },
      error: err,
      info,
      props,
      routeName,
    }

    logger.error(`[Checkout][Error][${componentTag}]`, {
      ...errorLog,
      owners: ['bot-squad-checkout-front'],
    })
  }
}
