<template>
  <div class="text-static-default-low">
    <p v-if="withCancel" class="caption">
      <FormattedMessage
        :definition="translations.cancel"
        :values="{ days: coolingOffDays }"
      >
        <template #link>
          <RevLink
            target="_blank"
            :to="
              toNuxtLink({
                type: 'internal',
                name: CMS.LEGAL_PAGE,
                params: {
                  pageName: LEGAL_PAGE_NAME.TERMS_SALE,
                  locale: currentLocale,
                },
              })
            "
          >
            {{ i18n(translations.cancelLink, { days: coolingOffDays }) }}
          </RevLink>
        </template>
      </FormattedMessage>
    </p>

    <p class="caption">
      <FormattedMessage :definition="text">
        <template #useTerms>
          <RevLink
            target="_blank"
            :to="
              toNuxtLink({
                type: 'internal',
                name: CMS.LEGAL_PAGE,
                params: {
                  pageName: LEGAL_PAGE_NAME.TERMS_USE,
                  locale: currentLocale,
                },
              })
            "
          >
            {{ i18n(translations.useTermsLink) }}
          </RevLink>
        </template>
        <template #saleTerms>
          <RevLink
            target="_blank"
            :to="
              toNuxtLink({
                type: 'internal',
                name: CMS.LEGAL_PAGE,
                params: {
                  pageName: LEGAL_PAGE_NAME.TERMS_SALE,
                  locale: currentLocale,
                },
              })
            "
          >
            {{ i18n(translations.saleTermsLink) }}
          </RevLink>
        </template>
        <template #swap>
          <RevLink
            v-if="swapStore.hasOffer"
            target="_blank"
            :to="
              toNuxtLink({
                type: 'internal',
                name: CMS.LEGAL_PAGE,
                params: {
                  pageName: LEGAL_PAGE_NAME.BUYBACK,
                  locale: currentLocale,
                },
              })
            "
          >
            {{ i18n(translations.swapLink) }}
          </RevLink>
        </template>
        <template #privacy>
          <RevLink
            anchor
            target="_blank"
            :to="
              toNuxtLink({
                type: 'internal',
                name: CMS.LEGAL_PAGE,
                params: {
                  pageName: LEGAL_PAGE_NAME.DATA_PROTECTION,
                  locale: currentLocale,
                },
              })
            "
          >
            {{ i18n(translations.privacyLink) }}
          </RevLink>
        </template>
        <template #termsOfService>
          <RevLink
            anchor
            target="_blank"
            :to="
              toNuxtLink({
                type: 'internal',
                name: CMS.LEGAL_PAGE,
                params: {
                  pageName: LEGAL_PAGE_NAME.TERMS_SERVICE,
                  locale: currentLocale,
                },
              })
            "
          >
            {{ i18n(translations.termsOfServiceLink) }}
          </RevLink>
        </template>
      </FormattedMessage>
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { toNuxtLink } from '@backmarket/nuxt-module-navigation/toNuxtLink'
import { RevLink } from '@ds/components/Link'

import { type Features } from '~/features'
import { CMS } from '~/scopes/cms/routes-names'
import { LEGAL_PAGE_NAME } from '~/scopes/legal/constants'

import { useSwapStore } from '../../stores/swapStore'

import translations from './CheckoutLegalLinks.translations'

const i18n = useI18n()
const currentLocale = useI18nLocale()
const swapStore = useSwapStore()
const { features } = useMarketplace<Features>()
const { coolingOffDays } = features

withDefaults(
  defineProps<{
    withCancel?: boolean
  }>(),
  {
    withCancel: false,
  },
)

const text = computed(() => {
  return swapStore.hasOffer ? translations.textWithSwap : translations.text
})
</script>
