import { useScriptTag } from '#imports'
import { type MaybeRefOrGetter, computed, toValue } from 'vue'

import type {
  PaymentMethod,
  PaypalPaymentMethodConfig,
} from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

import { PaymentMethodMisconfiguredError } from '../../../form-common/types/PaymentMethodMisconfiguredError'
import {
  PAYPAL_CLIENT_ID_PLACEHOLDER,
  PAYPAL_LIBRARY_URL,
} from '../../composables/usePaypalLibrary'

function usePaypalLibraryUrl(
  paypalPaymentMethod: MaybeRefOrGetter<PaymentMethod>,
) {
  return computed(() => {
    const { paypalClientId } = toValue(paypalPaymentMethod)
      .config as PaypalPaymentMethodConfig

    if (!paypalClientId) {
      throw new PaymentMethodMisconfiguredError(
        'Missing paypalClientId in PayPal payment method config',
      )
    }

    return PAYPAL_LIBRARY_URL.replace(
      PAYPAL_CLIENT_ID_PLACEHOLDER,
      paypalClientId,
    )
  })
}

// TODO, merge this with the other `usePaypalLibrary` (../composables/usePaypalLibrary.ts)
export function usePaypalLibrary(
  paypalPaymentMethod: MaybeRefOrGetter<PaymentMethod>,
  onLoaded?: Parameters<typeof useScriptTag>[1],
  options?: Parameters<typeof useScriptTag>[2],
) {
  return useScriptTag(
    usePaypalLibraryUrl(paypalPaymentMethod),
    onLoaded,
    options,
  )
}
