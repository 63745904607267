/**
 * A very simple "do nothing" JavaScript function, compatible with TypeScript.
 *
 * Since the noop accepts any arguments (and does nothing in return), it can be
 * used as a replacement of any function passed as a parameter to another
 * function, or a property of a Vue component.
 *
 * This is convenient to use this, instead of using `() => {}` (which requires
 * an ESLint exception), or a "do nothing comment".
 *
 * @see {@link https://eslint.org/docs/latest/rules/no-empty-function}
 */
// eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-explicit-any
export const noop: (..._args: any[]) => any = () => {}
