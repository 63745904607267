<template>
  <header
    class="bg-surface-default-low border-static-default-low flex items-center border-b py-18"
  >
    <Logo
      class="text-static-default-hi my-4 ml-12 flex h-16"
      :disabled="!isAllowedToLeave"
    />
  </header>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed } from 'vue'

import { CHECKOUT } from '../routes-names'

import Logo from './Logo.vue'

const route = useRoute()

const props = withDefaults(
  defineProps<{
    allowRedirection?: boolean
  }>(),
  { allowRedirection: false },
)

const isAllowedToLeave = computed(() => {
  if (props.allowRedirection) return true

  return route.name !== CHECKOUT.PAYMENT
})
</script>
