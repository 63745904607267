import type { UnionOfPropertyTypes } from '@backmarket/utils/object/UnionOfPropertyTypes'

/**
 * Represents a location on the BackMarket website, where payment advertising
 * is displayed.
 */
export const PaymentAdvertisingSpot = {
  /** On the cart page */
  CART: 'cart',

  /** On landing pages */
  LANDING: 'landing',

  /** In 'My Orders' */
  ORDERS: 'orders',

  /** On the product page */
  PRODUCT: 'product',

  /** Within search results */
  SEARCH: 'search',
} as const

export type PaymentAdvertisingSpot = UnionOfPropertyTypes<
  typeof PaymentAdvertisingSpot
>
