import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  total: {
    id: 'insurance_summary_total',
    defaultMessage: 'Total',
  },
  subtotal: {
    id: 'insurance_summary_subtotal',
    defaultMessage: 'Subtotal',
  },
  tax: {
    id: 'insurance_summary_tax',
    defaultMessage: 'Tax',
  },
}

export default translations
