import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  loading: {
    id: 'spinner',
    defaultMessage: 'Loading',
  },
}

export default translations
