<template>
  <RevCard class="body-2 space-y-16 px-24 py-24">
    <slot />

    <template v-if="tax">
      <RevDivider />

      <ul class="space-y-8" data-test="subtotals-list">
        <li class="flex justify-between gap-8">
          <span class="grow">{{ i18n(translations.subtotal) }}</span>
          <span>{{ i18n.price(subtotal) }}</span>
        </li>
        <li v-if="tax" class="flex justify-between gap-8">
          <span class="grow">{{ i18n(translations.tax) }}</span>
          <span>{{ i18n.price(tax) }}</span>
        </li>
      </ul>
    </template>

    <RevDivider />

    <div class="body-1-bold flex justify-between gap-8" data-test="total">
      <span class="grow">{{ i18n(translations.total) }}</span>
      <span>{{ i18n.price(total) }}</span>
    </div>

    <div class="space-y-24">
      <div class="space-y-8">
        <slot name="footer" />
        <CheckoutLegalLinks class="mt-24" />
      </div>
      <PaymentIcons :methods="marketPaymentMethods" type="networks" />
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import { type Price } from '@backmarket/http-api'
import {
  PaymentIcons,
  useMarketPaymentMethodsFor,
} from '@backmarket/nuxt-layer-payment/methods'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevDivider } from '@ds/components/Divider'

import CheckoutLegalLinks from '~/scopes/checkout/components/CheckoutLegalLinks/CheckoutLegalLinks.vue'

import translations from './SummaryCard.translations'

const i18n = useI18n()

const props = defineProps<{
  subtotal: Price
  tax: Price | null
  total: Price
}>()

const { data: marketPaymentMethods } = await useMarketPaymentMethodsFor({
  bagPrice: props.total,
  hasInsurance: true,
  tokenization: true,
})
</script>
