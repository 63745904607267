import type {
  PaymentMethod,
  PaymentMethodCode,
} from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'

import { type PaymentAdvertisingConfigFilter } from '../types/PaymentAdvertisingConfig'

/**
 * @returns A {@link PaymentAdvertisingConfigFilter payment advertising filter} that
 * will return `isAvailable: true` if the payment methods list contains any
 * of the given {@link bmCodes}.
 */
export function availableWhenAnyOf(
  ...bmCodes: PaymentMethodCode[]
): PaymentAdvertisingConfigFilter {
  return (paymentMethods: PaymentMethod[]) => {
    const forMethods = paymentMethods.filter((paymentMethod) =>
      bmCodes.includes(paymentMethod.bmCode),
    )

    return {
      isAvailable: forMethods.length > 0,
      forMethods,
    }
  }
}
