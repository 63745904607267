import { useRuntimeConfig } from '#imports'
import {
  type MaybeRefOrGetter,
  type Ref,
  computed,
  reactive,
  readonly,
  ref,
  toValue,
} from 'vue'

import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { type ComputedOptions } from '@backmarket/nuxt-module-http/ComputedOptions'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import { paymentMethodName } from '../../methods'
import { type PaymentAdvertisement } from '../types/PaymentAdvertisement'
import { type PaymentAdvertisementOptions } from '../types/PaymentAdvertisementOptions'
import { type PaymentAdvertisingSpot } from '../types/PaymentAdvertisingSpot'
import { findAvailablePaymentAdvertisement } from '../utils/findAvailablePaymentAdvertisement'

/**
 * Retrieve payment advertising information for a given
 * {@link PaymentAdvertisingSpot payment advertising spot}, based on a given
 * list of payment methods.
 *
 * @example
 *
 * const advertisement = usePaymentAdvertisement('cart', [
 *   { bmCode: 'card', ... },
 *   { bmCode: 'paypal', ... },
 * ])
 *
 * => {
 *   isAdvertised: true,
 *   method: { bmCode: 'card', ... },
 *   methodName: 'PayPal',
 *   // PaymentAdvertisingCartConfig here because spot is 'cart'
 *   config: {
 *     available: { id: '...', defaultMessage: '...' },
 *     unavailable: { id: '...', defaultMessage: '...' },
 *   }
 * }
 */
export function usePaymentAdvertisement<Spot extends PaymentAdvertisingSpot>(
  spot: MaybeRefOrGetter<Spot>,
  paymentMethods: MaybeRefOrGetter<PaymentMethod[]>,
  options: ComputedOptions<PaymentAdvertisementOptions> = {
    includeDisabledMethods: true,
  },
): Ref<PaymentAdvertisement<Spot>> {
  // Return no advertisement if the feature is disabled
  if (useRuntimeConfig().public.payment.KILL_ADVERTISING) {
    return readonly(
      ref({
        isAdvertised: false,
      }),
    )
  }

  const { market } = useMarketplace()
  const reactiveOptions = reactive(options)

  return computed(() => {
    const spotValue = toValue(spot)

    const availableAdvertisement = findAvailablePaymentAdvertisement<Spot>(
      spotValue,
      toValue(paymentMethods),
      { ...toValue(reactiveOptions), market },
    )

    return availableAdvertisement
      ? {
          isAdvertised: true,
          spot: spotValue,
          method: availableAdvertisement.methods[0],
          methods: availableAdvertisement.methods,
          methodName: paymentMethodName(
            availableAdvertisement.methods[0].bmCode,
          ),
          config: availableAdvertisement.config,
        }
      : {
          isAdvertised: false,
        }
  })
}
