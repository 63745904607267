import { type UseScriptTagOptions, useScriptTag } from '#imports'

import { noop } from '@backmarket/utils/function/noop'

import { SIGNIFYD_LIBRARY_URL } from '../config/constants'
import {
  getSignifydSessionId,
  resetSignifydSessionId,
} from '../utils/getSignifydSessionId'

const useSignifydLibrary = (
  sessionId: string,
  onLibraryLoaded: () => void,
  options: UseScriptTagOptions = {},
) => {
  return useScriptTag(SIGNIFYD_LIBRARY_URL, onLibraryLoaded, {
    ...options,
    attrs: {
      ...options.attrs,
      'data-order-session-id': sessionId,
      id: 'sig-api',
    },
  })
}

/**
 * Returns a unique session ID for Signifyd. Loads the library if needed.
 */
export const useSignifyd = () => {
  if (import.meta.server) {
    // We need cookies to store the session id
    // to simplify we don't load the library on the server
    return { load: noop, reset: noop }
  }

  const sessionId = getSignifydSessionId()
  const { load, unload, scriptTag } = useSignifydLibrary(sessionId, noop, {
    immediate: false,
    manual: true,
  })

  return {
    /** Load the signifyd library if needed and return the session id */
    load: async () => {
      if (scriptTag.value) {
        return sessionId
      }

      try {
        await load()

        return sessionId
      } catch (error) {
        // This script can be blocked by extensions like Ghostery.
        // To avoid blocking the payment it will make a silent catch
        // Also, useScriptTag should already have logged the error
      }

      return null
    },
    /** Unload the library and reset the signifyd session id */
    reset: async () => {
      await unload()
      resetSignifydSessionId()
    },
  }
}
