<template>
  <!-- eslint-disable-next-line vue/component-name-in-template-casing -->
  <klarna-placement
    data-key="credit-promotion-auto-size"
    :data-locale="locale"
    :data-purchase-amount="purchaseAmount"
  >
  </klarna-placement>
</template>

<script setup lang="ts">
/**
 * This component is a wrapper around the klarna custom element.
 * It takes care of formatting the props to match the klarna custom element API.
 * /!\ It's up to the parent component to load the klarna script with `useKlarnaLibrary`
 */
import { computed } from 'vue'

import type { Price } from '@backmarket/http-api'
import type { PaymentMethod } from '@backmarket/http-api/src/api-specs-payment/payment/payment.types'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { priceToMinorUnits } from '@backmarket/utils/math/priceToMinorUnits'
import { toRfc4646Locale } from '@backmarket/utils/string/toRfc4646Locale'

const props = defineProps<{
  paymentMethod: PaymentMethod
  basePrice: Price
}>()

const { market } = useMarketplace()
const locale = computed(() => toRfc4646Locale(market.defaultLocale))
const purchaseAmount = computed(() => priceToMinorUnits(props.basePrice))
</script>

<style scoped>
klarna-placement::part(osm-container) {
  background-color: transparent;
  font-size: inherit;
  border: none;
  padding: 0;
  text-align: left; /* to be consistent with the paypal banner */
}

klarna-placement::part(osm-badge-container) {
  width: 50px;
}
</style>
