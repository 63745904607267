import { Currency, type Price } from '@backmarket/http-api'

const CURRENCIES_PRECISION: Record<Currency, number> = {
  [Currency.AUD]: 2,
  [Currency.EUR]: 2,
  [Currency.GBP]: 2,
  [Currency.JPY]: 0,
  [Currency.SEK]: 2,
  [Currency.USD]: 2,
}

/**
 * Convert an price object amount to a minor unit amount.
 *
 * This helper is made to avoid some calculations errors due to JavaScript. For
 * example: 160.9 * 100 = 16090 but 160.8 * 100 = 16080.0000000002. So instead,
 * split the integer and the decimal part, multiply the integer part by 100 and
 * the decimal part by 10 and add them together. The 100 factor actually depends
 * on the currency precision. For example, the factor is 1 for currencies
 * without decimals.
 *
 * @param price The price to convert into minor units.
 * @return The amount converted to minor units.
 */
export const priceToMinorUnits = ({ currency, amount }: Price): number => {
  const [integer = '0', decimal = '0'] = amount.split('.')

  const precision = CURRENCIES_PRECISION[currency]

  let result = parseInt(integer, 10) * 10 ** precision

  for (let i = 1; i <= Math.min(precision, decimal.length); i += 1) {
    result += parseInt(decimal.charAt(i - 1), 10) * 10 ** (precision - i)
  }

  return result
}
