export default {
  cancel: {
    id: 'funnel_legal_links_cancel',
    defaultMessage:
      'Satisfaction guaranteed or your money back within {days} days',
  },
  cancelLink: {
    id: 'funnel_legal_links_cancel_link',
    defaultMessage: '{days} days',
  },
  text: {
    id: 'funnel_legal_links_text_terms',
    defaultMessage:
      'By confirming this order you accept our {useTerms, html}, {saleTerms, html}, {termsOfService, html} and our {privacy, html}',
  },
  textWithSwap: {
    id: 'funnel_legal_links_text_swap_terms',
    defaultMessage:
      'By confirming this order you accept our {useTerms, html}, our {saleTerms, html}, our {swap, html} and our {privacy, html}',
  },
  swapLink: {
    id: 'funnel_legal_links_swap_link',
    defaultMessage: 'Terms of returns Agreement',
  },
  useTermsLink: {
    id: 'funnel_legal_links_use_terms_link',
    defaultMessage: 'Terms of Use Agreement',
  },
  saleTermsLink: {
    id: 'funnel_legal_links_sale_terms_link',
    defaultMessage: 'Terms of Sale Agreement',
  },
  privacyLink: {
    id: 'funnel_legal_links_privacy_link',
    defaultMessage: 'data protection policy',
  },
  termsOfServiceLink: {
    id: 'funnel_legal_links_terms_of_service_link',
    defaultMessage: 'terms of service',
  },
}
