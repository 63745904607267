import { deleteBrowserCookie } from '@backmarket/utils/cookies/deleteBrowserCookie'
import { getBrowserCookie } from '@backmarket/utils/cookies/getBrowserCookie'
import { setBrowserCookie } from '@backmarket/utils/cookies/setBrowserCookie'
import { v4 as uuid } from 'uuid'

import {
  SIGNIFYD_COOKIE_MAX_AGE,
  SIGNIFYD_COOKIE_NAME,
} from '../config/constants'

/**
 * > The session ID is a random string that is unique to each checkout on your online store, not the user's browsing session.
 * > https://developer.signifyd.com/ecommerce-integration-guide/fingerprinting/#generate-session-id
 */
export const getSignifydSessionId = () => {
  const cookie = getBrowserCookie(SIGNIFYD_COOKIE_NAME)
  if (cookie) {
    return cookie
  }

  const sessionId = uuid()
  setBrowserCookie(SIGNIFYD_COOKIE_NAME, sessionId, {
    maxAge: SIGNIFYD_COOKIE_MAX_AGE,
  })

  return sessionId
}

/**
 * Reset the Signifyd session ID. Use this when the user has completed the checkout.
 */
export const resetSignifydSessionId = () => {
  deleteBrowserCookie(SIGNIFYD_COOKIE_NAME)
}
