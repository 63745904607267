import { type PaymentAdvertisingSpot } from '../../types/PaymentAdvertisingSpot'

import type {
  AffirmModalClass,
  AffirmModalPageType,
} from './AffirmModal.constants'

export const AFFIRM_SDK_URL_SANDBOX =
  'https://cdn1-sandbox.affirm.com/js/v2/affirm.js'
export const AFFIRM_SDK_URL_PRODUCTION =
  'https://cdn1.affirm.com/js/v2/affirm.js'

// Formatted for Affirm price in subunits.
// TODO [PAYIN-1843] Provide through API (Affirm payment method's `config`)
export const AFFIRM_MINIMAL_AMOUNT = 5000
export const AFFIRM_MAXIMAL_AMOUNT = 1750000

/**
 * Affirm modal settings
 */
export const AFFIRM_MODAL_SETTINGS: Record<
  PaymentAdvertisingSpot,
  {
    class: AffirmModalClass
    pageType: AffirmModalPageType
    requiresAmount: boolean
  }
> = {
  product: {
    class: 'affirm-product-modal',
    pageType: 'product',
    requiresAmount: true,
  },
  cart: {
    class: 'affirm-product-modal',
    pageType: 'cart',
    requiresAmount: true,
  },
  landing: {
    class: 'affirm-site-modal',
    pageType: 'category',
    requiresAmount: false,
  },
  orders: {
    class: 'affirm-site-modal',
    pageType: 'payment',
    requiresAmount: true,
  },
  search: {
    class: 'affirm-site-modal',
    pageType: 'category',
    requiresAmount: false,
  },
}
