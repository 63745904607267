import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { type UseScriptInput, useScript as _useScript } from '@unhead/vue'

const getScriptKey = (src: UseScriptInput) => {
  return typeof src === 'string' ? src : (src.key ?? src.src)
}

// TODO: Maybe we can merge this with useScriptTag at some point
export const useScript: typeof _useScript = (input, ...args) => {
  const result = _useScript(input, ...args)
  const logger = useLogger()

  const scriptKey = getScriptKey(input)

  result.onError((err) => {
    logger.error(`Failed to load script ${scriptKey}`, {
      type: 'SCRIPT_LOAD_ERROR',
      error: err,
      owners: ['bot-squad-payin-front'],
    })
  })

  return result
}
