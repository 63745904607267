import { type Market } from '@backmarket/http-api'

import {
  PAYMENT_METHODS_ADVERTISING,
  PAYMENT_METHODS_ADVERTISING_BY_COUNTRY,
} from '../config/advertising'
import type { PaymentAdvertisingConfig } from '../types/PaymentAdvertisingConfig'
import type { PaymentAdvertisingId } from '../types/PaymentAdvertisingId'

/**
 * Sorts payment methods for advertising based on the marketplace's country code
 * if a specific order is defined for the given market country code.
 */

export function sortPaymentMethodsAdvertisement(market: Market) {
  const paymentMethodsAdvertising = Object.entries(
    PAYMENT_METHODS_ADVERTISING,
  ) as [PaymentAdvertisingId, PaymentAdvertisingConfig][]

  if (market.countryCode in PAYMENT_METHODS_ADVERTISING_BY_COUNTRY) {
    const specificAdvertisingIdOrder =
      PAYMENT_METHODS_ADVERTISING_BY_COUNTRY[market.countryCode]
    if (specificAdvertisingIdOrder !== undefined) {
      return specificAdvertisingIdOrder.map(
        (id: PaymentAdvertisingId) =>
          [id, PAYMENT_METHODS_ADVERTISING[id]] as const,
      )
    }
  }

  return paymentMethodsAdvertising
}
